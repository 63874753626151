<template>
  <div>
    <v-card v-if="!isSuccess && !error" class="text-center pa-1" elevation="2">
      <v-card-title class="justify-center display-1 mb-2">{{ $t('auth.invite.title') }}</v-card-title>
      <v-card-subtitle>{{ $t('auth.invite.subtitle') }}</v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="newPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="errorNewPassword"
          :error-messages="errorNewPasswordMessage"
          :label="$t('auth.invite.password')"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          class="mt-4"
          name="password"
          outlined
          @change="resetErrors"
          @click:append="showPassword = !showPassword"
        />

        <v-btn
          :loading="isLoading"
          block
          color="primary"
          depressed
          x-large
          @click="confirmPasswordReset"
        >{{ $t('auth.invite.button') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card v-if="error" class="pa-1" elevation="2">
      <v-card-title class="justify-center">
        <span class="text-subtitle-1">Error with registration, please <a href="mailto:se2a-it@tu-braunschweig.de">contact us!</a></span>
      </v-card-title>
    </v-card>
    <v-card v-if="isSuccess" class="pa-1" elevation="2">
      <v-card-title class="justify-center">
        <span class="text-subtitle-1">{{ $t('auth.invite.success') }}</span>
      </v-card-title>
    </v-card>

    <div class="text-center mt-6">
      <router-link to="/auth/signin">
        {{ $t('auth.invite.back-to-signin') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      isLoading: false,
      isSuccess: false,

      // form
      showNewPassword: true,
      newPassword: '',

      // form error
      errorNewPassword: false,
      errorNewPasswordMessage: '',

      // show password field
      showPassword: false,

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  computed: {
    token() {
      return this.$route.params.token;
    }
  },
  methods: {
    confirmPasswordReset() {
      this.isLoading = true
      this.$api.go('/api/users/accept_invite').post({
        inviteCode: this.token,
        initialPassword: this.newPassword
      }).then((response) => {
        console.log(response);
        if(response.rawData['@type'] === 'hydra:Error') {
          this.error = true;
        } else {
          this.isSuccess = true;
        }

        this.isLoading = false;
      }).catch(e => {
        this.isLoading = false;
        this.errorNewPassword = true;
        this.errorNewPasswordMessage = e.description;
      })
    },
    resetErrors() {
      this.errorNewPassword = false
      this.errorNewPasswordMessage = ''
    }
  }
}

</script>

<style scoped>

</style>
